import React from 'react'
import styled from 'styled-components'

const IconGithubStyle = styled.div`
    cursor: pointer;

    :hover {
        svg g {
            fill: var(--accent-color);
        }
    }
`

const IconGithub = ({ socialLink }) => (
    <a href={socialLink} target="blank">
        <IconGithubStyle>
            <svg
                width="38px"
                height="38px"
                viewBox="0 0 38 38"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>icon-github</title>
                <desc>Created with Sketch.</desc>
                <g
                    id="icon-github"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M18.8064516,5 C10.4173387,5 4,11.3689516 4,19.7580645 C4,26.4657258 8.22177419,32.2056452 14.2520161,34.2258065 C15.0262097,34.3649194 15.2983871,33.8870968 15.2983871,33.4939516 C15.2983871,33.1189516 15.2802419,31.0504032 15.2802419,29.7802419 C15.2802419,29.7802419 11.046371,30.6875 10.1572581,27.9778226 C10.1572581,27.9778226 9.46774194,26.2177419 8.47580645,25.7641129 C8.47580645,25.7641129 7.09072581,24.8145161 8.57258065,24.8326613 C8.57258065,24.8326613 10.078629,24.953629 10.9072581,26.3931452 C12.2318548,28.7278226 14.4516129,28.0564516 15.3165323,27.6572581 C15.4556452,26.6895161 15.8487903,26.0181452 16.2842742,25.6189516 C12.9032258,25.2439516 9.49193548,24.7540323 9.49193548,18.9354839 C9.49193548,17.2721774 9.9516129,16.4375 10.9193548,15.3729839 C10.7620968,14.9798387 10.2479839,13.358871 11.0766129,11.266129 C12.3407258,10.8729839 15.25,12.8991935 15.25,12.8991935 C16.4596774,12.5604839 17.7600806,12.3850806 19.0483871,12.3850806 C20.3366935,12.3850806 21.6370968,12.5604839 22.8467742,12.8991935 C22.8467742,12.8991935 25.7560484,10.8669355 27.0201613,11.266129 C27.8487903,13.3649194 27.3346774,14.9798387 27.1774194,15.3729839 C28.1451613,16.4435484 28.7379032,17.2782258 28.7379032,18.9354839 C28.7379032,24.7721774 25.1754032,25.2379032 21.7943548,25.6189516 C22.3508065,26.0967742 22.8225806,27.0040323 22.8225806,28.4254032 C22.8225806,30.4637097 22.8044355,32.9858871 22.8044355,33.4818548 C22.8044355,33.875 23.0826613,34.3528226 23.8508065,34.2137097 C29.8991935,32.2056452 34,26.4657258 34,19.7580645 C34,11.3689516 27.1955645,5 18.8064516,5 Z"
                        id="Shape"
                        stroke="#EE0202"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </IconGithubStyle>
    </a>
)

export default IconGithub
