import React from 'react'
import styled from 'styled-components'

const IconBehanceStyle = styled.div`
    cursor: pointer;

    :hover {
        svg g {
            fill: var(--accent-color);
        }
    }
`

const IconBehance = ({ socialLink }) => (
    <a href={socialLink} target="blank">
        <IconBehanceStyle>
            <svg
                width="38px"
                height="38px"
                viewBox="0 0 38 38"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>icon-behance</title>
                <desc>Created with Sketch.</desc>
                <g
                    id="icon-behance"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M16.2916667,19.2697917 C18.1135417,18.3989583 19.0645833,17.08125 19.0645833,15.0302083 C19.0645833,10.9854167 16.0510417,10 12.5734375,10 L3,10 L3,30.3041667 L12.8427083,30.3041667 C16.5322917,30.3041667 19.9984375,28.5338542 19.9984375,24.4088542 C19.9984375,21.859375 18.7895833,19.9744792 16.2916667,19.2697917 L16.2916667,19.2697917 Z M7.46302083,13.4661458 L11.6510417,13.4661458 C13.2609375,13.4661458 14.7104167,13.91875 14.7104167,15.7864583 C14.7104167,17.5109375 13.5817708,18.2041667 11.9890625,18.2041667 L7.46302083,18.2041667 L7.46302083,13.4661458 L7.46302083,13.4661458 Z M12.2354167,26.8552083 L7.46302083,26.8552083 L7.46302083,21.2635417 L12.3270833,21.2635417 C14.2921875,21.2635417 15.5354167,22.0828125 15.5354167,24.1625 C15.5354167,26.2135417 14.0515625,26.8552083 12.2354167,26.8552083 L12.2354167,26.8552083 Z M32.7744792,13.0651042 L24.5416667,13.0651042 L24.5416667,11.065625 L32.7744792,11.065625 L32.7744792,13.0651042 L32.7744792,13.0651042 Z M36,23.165625 C36,18.8171875 33.45625,15.190625 28.8442708,15.190625 C24.3640625,15.190625 21.321875,18.559375 21.321875,22.9708333 C21.321875,27.5484375 24.2036458,30.6880208 28.8442708,30.6880208 C32.35625,30.6880208 34.6307292,29.1067708 35.725,25.74375 L32.1614583,25.74375 C31.7776042,26.9984375 30.1963542,27.6630208 28.9703125,27.6630208 C26.6041667,27.6630208 25.3609375,26.2765625 25.3609375,23.921875 L35.965625,23.921875 C35.9828125,23.68125 36,23.4234375 36,23.165625 Z M25.3666667,21.378125 C25.4984375,19.4473958 26.7817708,18.2385417 28.7182292,18.2385417 C30.7463542,18.2385417 31.7661458,19.4302083 31.9380208,21.378125 L25.3666667,21.378125 Z"
                        id="Shape"
                        stroke="#EE0202"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </IconBehanceStyle>
    </a>
)

export default IconBehance
